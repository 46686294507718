const BASE = process.env.VUE_APP_BASE
const BASE_AUTH = process.env.VUE_APP_AUTH_ROOT
const BASE_REST = process.env.VUE_APP_REST_ROOT

const BASE_AUTH_LOGIN = `${BASE_AUTH}${process.env.VUE_APP_AUTH_LOGIN}`
const BASE_AUTH_LOGOUT = `${BASE_AUTH}${process.env.VUE_APP_AUTH_LOGOUT}`
const BASE_AUTH_CHECK = `${BASE_AUTH}${process.env.VUE_APP_AUTH_CHECK}`
const BASE_AUTH_DATA = `${BASE_AUTH}${process.env.VUE_APP_AUTH_DATA}`
const BASE_AUTH_TRAININGDATA = `${BASE_AUTH}${process.env.VUE_APP_AUTH_TRAINING}`

const BASE_REST_CREATEUSER = `${BASE}${process.env.VUE_APP_CREATEUSER}`
const BASE_REST_PRACTICEROUND = `${BASE_REST}${process.env.VUE_APP_REST_ROUNDMANAGER}`
const BASE_REST_COMPARE = `${BASE_REST}${process.env.VUE_APP_REST_COMPARE}`
const BASE_REST_USERWORDS = `${BASE_REST}${process.env.VUE_APP_REST_USERWORDS}`
const BASE_REST_SESSION = `${BASE_REST}${process.env.VUE_APP_REST_SESSION}`

export default {
  BASE,
  BASE_AUTH,
  BASE_REST,
  BASE_AUTH_LOGIN,
  BASE_AUTH_LOGOUT,
  BASE_AUTH_CHECK,
  BASE_AUTH_DATA,
  BASE_AUTH_TRAININGDATA,
  BASE_REST_CREATEUSER,
  BASE_REST_PRACTICEROUND,
  BASE_REST_COMPARE,
  BASE_REST_USERWORDS,
  BASE_REST_SESSION
}
